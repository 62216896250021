import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';

import ArrowIcon from './Icons/ArrowIcon';

// Pagination truncation from @jorrit91:
//  https://gist.github.com/kottenator/9d936eb3e4e3c3e02598?permalink_comment_id=3238804#gistcomment-3238804
const getRange = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, i) => i + start);
};

const pagination = (currentPage, pageCount) => {
  let delta;

  if (pageCount <= 7) {
    // delta === 7: [1 2 3 4 5 6 7]
    delta = 7;
  } else {
    // delta === 2: [1 ... 4 5 6 ... 10]
    // delta === 4: [1 2 3 4 5 ... 10]
    delta = currentPage > 4 && currentPage < pageCount - 3 ? 2 : 4;
  }

  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === pageCount) {
    range.start += 1;
    range.end += 1;
  }

  let pages =
    currentPage > delta
      ? getRange(
          Math.min(range.start, pageCount - delta),
          Math.min(range.end, pageCount)
        )
      : getRange(1, Math.min(pageCount, delta + 1));

  const withDots = (value, pair) =>
    pages.length + 1 !== pageCount ? pair : [value];

  if (pages[0] !== 1) {
    pages = withDots(1, [1, '...']).concat(pages);
  }

  if (pages[pages.length - 1] < pageCount) {
    pages = pages.concat(withDots(pageCount, ['...', pageCount]));
  }

  return pages;
};

const Pagination = ({ numPages, currentPage }) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? '/stories/'
      : `/stories/${(currentPage - 1).toString()}`;
  const nextPage = `/stories/${(currentPage + 1).toString()}`;

  const pageNumbers = pagination(currentPage, numPages);

  return (
    <Wrapper>
      <Link
        to={prevPage}
        rel="prev"
        className={`button ${isFirstPage ? 'disabled-link' : null}`}
      >
        <span className="sr-only">Previous page</span>
        <ArrowIcon direction="left" stroke="var(--color-navy)" />
      </Link>

      <PageNumbers>
        {pageNumbers.map(item => {
          if (typeof item === 'number') {
            return (
              <Link
                key={`pagination-number${item}`}
                to={`/stories/${item === 1 ? '' : item}`}
              >
                {item}
              </Link>
            );
          } else {
            return <span key={'ellipsis'}>...</span>;
          }
        })}
      </PageNumbers>

      <Link
        to={nextPage}
        rel="next"
        className={`button ${isLastPage ? 'disabled-link' : null}`}
      >
        <span className="sr-only">Next page</span>
        <ArrowIcon direction="right" stroke="var(--color-navy)" />
      </Link>
    </Wrapper>
  );
};

export default Pagination;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--space-xxl);
  background-color: var(--color-snow);
  padding: var(--space-m) 0;
  border-bottom: 0.1rem solid var(--color-navy);
  z-index: 1;

  @media (${bp.min.sm}) {
    margin-bottom: 0;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    background-color: var(--color-snow);
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: -1;
  }

  .disabled-link {
    pointer-events: none;
    opacity: 0.25;
  }

  .button {
    --size: 4.4rem;

    width: var(--size);
    height: var(--size);
    border: 0.1rem solid var(--color-navy);
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 250ms var(--ease-cubic-in-out);

    @media (${bp.min.xs_sm}) {
      --size: 5.6rem;
    }

    svg {
      transition: all 250ms var(--ease-cubic-in-out);
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-navy);

        svg {
          stroke: var(--color-snow);
        }
      }
    }
  }
`;

const PageNumbers = styled.div`
  a,
  span {
    font-size: clamp(2.2rem, 4vw, 2.8rem);
    line-height: 1;
    letter-spacing: -0.15rem;
    font-weight: var(--font-weight-bold);
    text-align: center;
    padding: var(--space-xxxs);
    margin: 0 var(--space-xxxs);

    @media (${bp.min.xs_sm}) {
      padding: var(--space-xxs);
      margin: 0 var(--space-xs);
    }

    &[aria-current] {
      color: var(--color-great-blue);
      pointer-events: none;
    }
  }
`;
