import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';

import Seo from '../components/Seo';
import LatestStory from '../components/LatestStory';
import Stories from '../components/Stories';

import { PrismicRichText } from '@prismicio/react';
import Pagination from '../components/Pagination';

const StoriesPage = ({ data, pageContext }) => {
  const storiesData = data.prismicStories;
  const storyData = data.allPrismicStory;
  const { header, subheader, intro, new_stories_header, stories_grid_header } =
    storiesData.data;

  const stories = storyData.edges;

  const { showLatest: showLatestStory, numPages, currentPage } = pageContext;

  const { setStoryHeaderColor, setPageBgColor } = useContext(GlobalContext);

  useEffect(() => {
    setPageBgColor('var(--color-snow)');
    setStoryHeaderColor('var(--color-snow)');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Intro>
        <Title>{header}</Title>
        <Subtitle>{subheader}</Subtitle>

        <Description>
          <PrismicRichText field={intro.richText} />
        </Description>
      </Intro>

      {showLatestStory && <LatestStory />}

      <Stories
        showLatestStory={showLatestStory}
        stories={stories}
        newStoriesHeader={new_stories_header}
        storiesGridHeader={stories_grid_header}
      />

      {numPages > 1 && (
        <Pagination numPages={numPages} currentPage={currentPage} />
      )}
    </>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data }) => (
  <Seo
    title={data.prismicStories.data.title}
    description={data.prismicStories.data.meta_description}
    image={data.prismicStories.data.meta_image.url}
  />
);

export default withPrismicPreview(StoriesPage);

/**
 * Styled Components
 *
 */
const Intro = styled.section`
  width: 100%;
  max-width: 96rem;
  padding-bottom: 4.8rem;
  margin-top: 10.8rem;

  @media (${bp.min.sm}) {
    padding: 4rem 0 6rem;
  }

  @media (${bp.min.sm_md}) {
    margin-top: 0;
    padding: 16rem 0 8rem;
  }
`;

const Title = styled.h1`
  font-family: var(--font-family-national-condensed);
  font-size: clamp(8rem, 5.989rem + 8.58vw, 15rem);
  line-height: 0.8;
  letter-spacing: -0.02rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: var(--space-s);

  @media (${bp.min.sm}) {
    position: absolute;
    left: -1000rem;
    top: auto;
    width: 0.1rem;
    height: 0.1rem;
    overflow: hidden;
  }
`;

const Subtitle = styled.p`
  font-size: 2.2.rem;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  line-height: 1;
  text-align: center;
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-m);
  padding-bottom: var(--space-s);
  border-bottom: 0.1rem solid var(--color-navy);

  @media (${bp.min.sm}) {
    display: none;
  }
`;

const Description = styled.div`
  font-size: clamp(2.4rem, 1.883rem + 2.21vw, 4.2rem);
  line-height: 1.15;
  letter-spacing: -0.2rem;

  @media (${bp.min.sm}) {
    font-weight: var(--font-weight-bold);
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query StoryPageQuery($limit: Int!, $skip: Int!) {
    prismicStories {
      _previewable
      data {
        title
        header
        subheader
        intro {
          richText
        }
        new_stories_header
        stories_grid_header
        meta_description
        meta_image {
          url
        }
      }
    }

    allPrismicStory(
      sort: { fields: first_publication_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          _previewable
          ...StoryCard
        }
      }
    }
  }
`;
